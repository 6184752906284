<!-- * When the language in English * -->
<ng-container *ngIf="isLanguageEN">
  <img
    style="width: 100%"
    class="desktop-graphic"
    src="assets/images/tomove/guide_desktop_eng.png"
    alt="guide_desktop_eng"
  />
  <div
    mat-dialog-content
    class="mobile-dialog-content"
    style="padding: 0; margin: 0"
  >
    <img
      src="assets/images/tomove/guide_mobile_eng.png"
      alt="guide_mobile_eng"
    />
  </div>
</ng-container>
<!-- * When the language in Malay * -->
<ng-container *ngIf="!isLanguageEN">
  <img
    style="width: 100%"
    class="desktop-graphic"
    src="assets/images/tomove/guide_desktop_bm.png"
    alt=""
  />
  <div
    mat-dialog-content
    class="mobile-dialog-content"
    style="padding: 0; margin: 0"
  >
    <img
      src="assets/images/tomove/guide_mobile_bm.png"
      alt="guide_mobile_eng"
    />
  </div>
</ng-container>

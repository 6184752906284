import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-flex-container',
  templateUrl: './flex-container.component.html',
  styleUrls: ['./flex-container.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ]
})
export class FlexContainerComponent implements OnInit {
  @Input() title: string = '';
  @Input() titleBelow: string = '';
  @Input() value: string = '';
  @Input() type: 'total' | 'discount' | 'bold' | 'normal' | '' = '';
  @Input() currency: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  IApplyPromoCodePayload,
  IEditQuotationPayload,
  IEppRates,
  IGenerateBundlePaymentPayload,
  IMotorBundleAddOn,
  IMotorExcess,
  IMotorQuotation,
  IPromoCode,
  ITopupPaymentPayload,
  IVehicleListing,
} from "../../../shared/interfaces/quotation.interface";
import { api } from "src/app/shared/constants/api/api-url";
import { ApiService } from "src/app/shared/constants/api/api.service";
import { IPaymentResData } from "../../payment/payment-success/payment-success.component";

@Injectable({
  providedIn: "root",
})
export class QuotationService {
  private basePath: string = `${environment.url}/api/Motor/`;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getQuotations(id: string) {
    return this.apiService.get(api.GET_QUOTATION + `/${id}?fromCRM=false`);
  }

  generateBundlePayment(id: string, values: IGenerateBundlePaymentPayload) {
    return this.http.post(
      this.basePath + api.GENERATE_BUNDLE_PAYMENT + `/${id}`,
      values,
    );
  }

  getMotorBundleAddOn(
    id: string,
    quotationId: string,
  ): Observable<IMotorBundleAddOn> {
    return this.http.get<IMotorBundleAddOn>(
      this.basePath +
        api.GET_MOTOR_BUNDLE_ADDON +
        `/${id}?quotationId=` +
        quotationId,
    );
  }

  getMotorExcess(quotationId: string) {
    return this.http.get<IMotorExcess>(
      this.basePath + api.GET_MOTOR_EXCESS + `/${quotationId}`,
    );
  }

  editCarVariant(linkId: string, vehicleId: string) {
    return this.http.post(
      this.basePath +
        api.EDIT_CAR_VARIANT +
        `/${linkId}?vehicleId=${vehicleId}`,
      null,
    );
  }

  editQuotation(id: string, values: IEditQuotationPayload) {
    return this.http.post<void>(
      this.basePath + api.EDIT_QUOTATION + `/${id}`,
      values,
    );
  }

  applyPromoCode(id: string, values: IApplyPromoCodePayload) {
    return this.http.post<IPromoCode>(
      this.basePath + api.APPLY_PROMO_CODE + `/${id}`,
      values,
    );
  }

  getEPPRates(): Observable<IEppRates> {
    return this.http.get<IEppRates>(this.basePath + api.GET_EPP_RATES);
  }

  // Edit Quotation
  getVehicleListing(limit: number, offset: number, text: string, make: string) {
    return this.http.post<IVehicleListing[]>(
      this.basePath + api.GET_VEHICLE_MODEL_LISTING,
      {
        limit,
        offset,
        text,
        make,
      },
    );
  }

  getAllProduct(id: string) {
    return this.http.get(this.basePath + `${api.GET_ALL_PRODUCT}/${id}`);
  }

  getEppBankInstallmentList() {
    return this.http.get(`${this.basePath}GetEppRates/`);
  }

  editRequest(id: string, val: IEditQuotationPayload) {
    return this.http.post<void>(
      this.basePath + `${api.EDIT_QUOTATION}/${id}`,
      val,
    );
  }

  checkPayment(refNo: string): Observable<IPaymentResData | any> {
    return this.apiService.get(api.CHECK_PAYMENT + `?refNo=${refNo}`);
  }

  // Topup Request
  getPolicyTopupDetail(id: string) {
    return this.apiService.get(api.GET_POLICY_TOPUP_DETAIL + `/${id}`);
  }
  generateTopupPayment(id: string, values: ITopupPaymentPayload) {
    return this.apiService.post(api.GENERATE_TOPUP_PAYMENT + `/${id}`, values);
  }

  // Upload documents
  getPolicyPhotos(id: string) {
    return this.apiService.get(api.GET_POLICY_PHOTOS + `/${id}`);
  }

  uploadPhoto(id: string, file: any) {
    return this.apiService.post(api.UPLOAD_PHOTO + `/${id}`, file);
  }

  // Payment
  getPaymentById(id: string) {
    return this.apiService.get(api.GET_PAYMENT + `/${id}`);
  }
}

import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner.service";
import { QuotationService } from "../quotation/_services/quotation.service";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent {
  partnerCode: string = "ps";
  obj: any;
  constructor(
    activatedRoute: ActivatedRoute,
    loadingService: LoadingSpinnerService,
    quotationService: QuotationService,
  ) {
    let id = activatedRoute.snapshot.queryParams.id;
    loadingService.loadingOn(true);
    if (!id) window.location.href = "/";
    else {
      quotationService.getPaymentById(id).subscribe({
        next: (x) => {
          var data = JSON.parse(x.data);
          if (x.provider == "Shopback" || x.provider == "Razer") {
            window.location.href = data.Url;
          } else if (x.provider == "Payex") {
            window.location.href = data.Result[0].Url;
          } else if (x.provider == "IPay88") {
            this.obj = data;
            setTimeout(() => {
              document.getElementById("ipay88").click();
            }, 100);
          }
        },
        error: () => {
          window.location.href = "/";
        },
      });
    }
  }
}

<div class="footer">
  <div class="footer-container">
    <div class="footer__logo">
      <span>Powered by</span>
      <img
        src="https://storage.googleapis.com/ps-asset/ps_logos/ps_logo_box.png"
        alt=""
        class="footer__ps-logo"
      />
    </div>
    <div class="footer__address">
      D-3A-06 & D-3A-07, Menara Suezcap 1, KL Gateway, No. 2 Jalan Kerinchi,
      Gerbang Kerinchi Lestari, 59200 Kuala Lumpur
      <div class="footer__info mt-md">
        <img
          src="https://car.policystreet.app/assets/icons/footer/whatsapp-fill.png"
          alt=""
        />
        <div>+6014 370 3130</div>
      </div>
      <div class="footer__info">
        <img
          src="https://car.policystreet.app/assets/icons/footer/email-fill.png"
          alt=""
        />
        <div>customerservice&#64;policystreet.com</div>
      </div>
    </div>
    <div class="footer__col">
      <div>
        <div class="mb-sm">{{ "footer.paymentMethod" | translate }}</div>
        <div>
          <img src="./assets/icons/bank_payment/visa.svg" alt="visa" />
          <img
            src="./assets/icons/bank_payment/mastercard.svg"
            alt="mastercard"
          />
          <img src="./assets/icons/bank_payment/fpx.svg" alt="fpx" />
          <img src="./assets/icons/bank_payment/tng.svg" alt="tng" />
          <img src="./assets/icons/bank_payment/grabpay.svg" alt="grabpay" />
          <img src="./assets/icons/bank_payment/boost.svg" alt="boost" />
          <img src="./assets/icons/bank_payment/shopback.svg" alt="shopback" />
        </div>
      </div>
    </div>
    <div class="footer__col">
      <div>
        <div class="mb-sm">{{ "footer.acceptedEppBanks" | translate }}</div>
        <div>
          <img src="./assets/icons/bank_payment/maybank.svg" alt="maybank" />
          <img src="./assets/icons/bank_payment/cimb.svg" alt="cimb" />
          <img src="./assets/icons/bank_payment/rhb.svg" alt="rhb" />
          <img src="./assets/icons/bank_payment/am.svg" alt="ambank" />
          <img src="./assets/icons/bank_payment/hsbc.svg" alt="hsbc" />
          <img src="./assets/icons/bank_payment/ocbc.svg" alt="ocbc" />
          <img src="./assets/icons/bank_payment/public.svg" alt="public" />
          <img src="./assets/icons/bank_payment/affin.svg" alt="affin" />
          <img src="./assets/icons/bank_payment/hlb.svg" alt="hlb" />
          <img src="./assets/icons/bank_payment/uob.svg" alt="uob" />
        </div>
      </div>
    </div>
  </div>
  <div class="footer-note">
    <div class="footer-note-container">
      {{ "footer.licensed" | translate }}
    </div>
  </div>
</div>

import { NgIf } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-sample-dialog",
  templateUrl: "./sample-dialog.component.html",
  styleUrls: ["./sample-dialog.component.scss"],
  standalone: true,
  imports: [NgIf],
})
export class SampleDialogComponent {
  for: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.for = data;
  }
}

import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LANGUAGE } from "../../constants/constants.global";

@Component({
  selector: "app-guides-dialog",
  templateUrl: "./guides-dialog.component.html",
  styleUrls: ["./guides-dialog.component.scss"],
  standalone: true,
  imports: [NgIf, MatDialogModule],
})
export class GuidesDialogComponent {
  constructor(public translate: TranslateService) {}

  get isLanguageEN() {
    return this.translate.currentLang === LANGUAGE.en;
  }

  switch(lang: string) {
    this.translate.use(lang);
  }
}

import { enableProdMode, importProvidersFrom } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { provideRouter } from "@angular/router";
import {
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { APP_ROUTES } from "./app/app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { HttpRequestInterceptor } from "./app/shared/services/http.service";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import clevertap from "clevertap-web-sdk";
import { environment } from "./environments/environment";
import { provideLottieOptions } from "ngx-lottie";
import player from "lottie-web";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

if (environment.cleverTapId) {
  clevertap.privacy.push({ optOut: false });
  clevertap.privacy.push({ useIP: true });
  clevertap.init(environment.cleverTapId, "sg1");
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    provideLottieOptions({
      player: () => player,
    }),
    provideEnvironmentNgxMask(),
    importProvidersFrom(MatSnackBarModule, HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    provideRouter(APP_ROUTES),
    provideAnimations(),
  ],
}).catch((err) => console.log(err));

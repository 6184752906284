import {
  AsyncPipe,
  DatePipe,
  DecimalPipe,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { IEndorsementPaymentDetail } from "src/app/modules/endorsement/_interfaces/endorsement.interface";
import { FlexContainerComponent } from "src/app/shared/components/flex-container/flex-container.component";
import { PaymentOptionsComponent } from "src/app/shared/components/payment-options/payment-options.component";
import { SectionTitleComponent } from "src/app/shared/components/section-title/section-title.component";
import {
  PAYMENT_OPTION_FP,
  PAYMENT_OPTION_RAZER,
  PAYMENT_OPTION_VISA,
} from "src/app/shared/constants/payment-options";
import { AlertService } from "src/app/shared/services/alert.service";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner.service";
import { ScreenSizeService } from "src/app/shared/services/screen-size.service";

@Component({
  selector: "app-request-payment-details",
  templateUrl: "./request-payment-details.component.html",
  styleUrls: ["./request-payment-details.component.scss"],
  standalone: true,
  imports: [
    AsyncPipe,
    TranslateModule,
    NgIf,
    MatCardModule,
    SectionTitleComponent,
    FlexContainerComponent,
    PaymentOptionsComponent,
    DatePipe,
    DecimalPipe,
    NgTemplateOutlet,
    MatButtonModule,
  ],
})
export class RequestPaymentDetailsComponent implements OnInit, OnDestroy {
  paymentFormGroup: FormGroup;
  id: string;

  @Input() data$: Observable<any>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private alertService: AlertService,
    private loadingService: LoadingSpinnerService,
    public screenSizeService: ScreenSizeService,
    public activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Listen for window resize events
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );

    // Initialize form group and get endorsement data
    this.initPaymentFormGroup();
    this.id = this.activatedRoute.snapshot.params.id;
  }

  ngOnDestroy(): void {
    // Unsubscribe from observables to prevent memory leaks
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initPaymentFormGroup() {
    this.paymentFormGroup = new FormGroup({
      paymentType: new FormControl(PAYMENT_OPTION_FP, [Validators.required]),
      paymentProvider: new FormControl(PAYMENT_OPTION_RAZER, [
        Validators.required,
      ]),
      paymentChannel: new FormControl(PAYMENT_OPTION_VISA, []),
      ewalletType: new FormControl("", []),
      bank: new FormControl("", []),
      installmentMonth: new FormControl(0, []),
    });
  }

  getTax(endorsementData: IEndorsementPaymentDetail): number {
    return endorsementData?.total * endorsementData?.serviceChargeRate;
  }

  getTotalPrice(endorsementData: IEndorsementPaymentDetail): number {
    return endorsementData?.total + this.getTax(endorsementData);
  }

  getIdentityNoLabel() {
    return this.data$.pipe(
      map((endorsementData) => {
        if (endorsementData?.isMalaysian && !endorsementData?.isCompany)
          return "forms.icNo";
        else if (!endorsementData?.isMalaysian) return "forms.passport";
        return "forms.businessRegistrationNo";
      }),
    );
  }

  getNameLabel() {
    return this.data$.pipe(
      map((endorsementData) => {
        if (endorsementData?.isMalaysian && !endorsementData?.isCompany)
          return "forms.namePerIc";
        else if (!endorsementData?.isMalaysian) return "forms.namePerPassport";
        return "forms.companyName";
      }),
    );
  }
}

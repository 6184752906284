export const api = {
  // Request Quotation
  GET_COUNTRY_SELECTION: "Microsite/GetCountrySelection",
  SUBMIT_REQUEST_QUOTATION: "Microsite/Submit",

  // Quotation
  GET_QUOTATION: "Motor/GetQuotation",
  GENERATE_BUNDLE_PAYMENT: "Motor/GenerateBundlePayment",
  GET_MOTOR_BUNDLE_ADDON: "Motor/GetMotorBundleAddOn",
  GET_MOTOR_EXCESS: "Motor/GetMotorExcess",
  EDIT_CAR_VARIANT: "Motor/EditCarVariant",
  EDIT_QUOTATION: "Motor/EditRequest",
  APPLY_PROMO_CODE: "Motor/PromoCode",
  GET_EPP_RATES: "Motor/GetEppRates",
  GET_VEHICLE_MODEL_LISTING: "Motor/GetVehicleModelListing",
  GET_ALL_PRODUCT: "Motor/GetAllProduct",
  CHECK_PAYMENT: "Motor/CheckPayment",
  GET_POLICY_TOPUP_DETAIL: "Motor/GetPolicyTopUpDetail",
  GENERATE_TOPUP_PAYMENT: "Motor/GenerateTopUpPayment",
  GET_POLICY_PHOTOS: "Motor/GetPolicyPhotos",
  UPLOAD_PHOTO: "Motor/UploadPhoto",
  GET_PAYMENT: "Motor/GetPayment",

  // Endorsement
  VERIFY_POLICY_HOLDER: "EndorsementSubmission/VerifyPolicyHolder",
  GET_ENDORSEMENT_TYPE_LIST: "EndorsementSubmission/GetEndorsementTypeList",
  SUBMIT_ENDORSEMENT: "EndorsementSubmission/SubmitEndorsement",
  GET_ENDORSEMENT_PAYMENT_DETAIL: "Endorsement/GetEndorsementPaymentDetail",
  GENERATE_ENDORSEMENT_PAYMENT: "Endorsement/GenerateEndorsementPayment",
};

<div class="flex-container" [ngClass]="type === 'total' ? 'bold' : null">
    <div class="flex-container__title">{{ title }}
      <ng-container *ngIf="titleBelow">
        <br />
        <span class="flex-container__titleBelow">
          {{ titleBelow }}
        </span>
      </ng-container>
    </div>
    <div class="flex-container__bold" [ngClass]="type === 'discount' ? 'discount-color' : type == 'normal' ? 'no-bold' : null">
      <span *ngIf="type == 'discount'">- </span>
      <span *ngIf="type == 'discount' || currency">RM </span>{{ value }}
    </div>
  </div>
  
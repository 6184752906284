import { DecimalPipe, NgFor, NgIf } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import {
  PAYMENT_EPP_TYPE,
  PAYMENT_EWALLET_TYPE,
  PAYMENT_OPTION_EPP,
  PAYMENT_OPTION_EWALLET,
  PAYMENT_OPTION_FP,
  PAYMENT_OPTION_FPX,
  PAYMENT_OPTION_PAYEX,
  PAYMENT_OPTION_RAZER,
  PAYMENT_OPTION_SHOPBACK,
  PAYMENT_OPTION_VISA,
} from "../../constants/payment-options";
import { MatOptionModule } from "@angular/material/core";

@Component({
  selector: "app-payment-options",
  templateUrl: "./payment-options.component.html",
  styleUrls: ["./payment-options.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatRadioModule,
    NgIf,
    TranslateModule,
    DecimalPipe,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
  ],
})
export class PaymentOptionsComponent implements OnInit, OnChanges {
  paymentOptionShopback: string = PAYMENT_OPTION_SHOPBACK;
  paymentOptionFullPrice: string = PAYMENT_OPTION_FP;
  paymentOptionRazer: string = PAYMENT_OPTION_RAZER;
  paymentOptionEPP: string = PAYMENT_OPTION_EPP;
  paymentOptionPayex: string = PAYMENT_OPTION_PAYEX;

  paymentOptionVisa: string = PAYMENT_OPTION_VISA;
  paymentOptionEwallet: string = PAYMENT_OPTION_EWALLET;
  paymentOptionFpx: string = PAYMENT_OPTION_FPX;

  ewalletTypeListing = PAYMENT_EWALLET_TYPE;
  eppTypeListing = PAYMENT_EPP_TYPE;

  totalPriceAfterCharge: number;

  @Input() totalPrice: number;
  @Input() totalPriceBeforeCharges: number;
  @Input() bankCharge: { 3: number; 6: number; 12: number };
  @Input() paymentFormGroup: FormGroup;

  @Input() onlyFullPayment?: boolean = false;
  @Input() allowEwallet?: boolean = true;

  totalPriceInstallment3: number;
  totalPriceInstallment6: number;
  totalPriceInstallment12: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.totalPriceInstallment3 =
      (this.totalPriceBeforeCharges + this.bankCharge?.["3"]) / 3;
    this.totalPriceInstallment6 =
      (this.totalPriceBeforeCharges + this.bankCharge?.["6"]) / 6;
    this.totalPriceInstallment12 =
      (this.totalPriceBeforeCharges + this.bankCharge?.["12"]) / 12;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Do this to prevent ExpressionChangedAfterItHasBeenCheckedError
    if (changes.bankCharge && !changes.bankCharge.firstChange) {
      this.totalPriceInstallment3 =
        (this.totalPriceBeforeCharges + this.bankCharge?.["3"]) / 3;
      this.totalPriceInstallment6 =
        (this.totalPriceBeforeCharges + this.bankCharge?.["6"]) / 6;
      this.totalPriceInstallment12 =
        (this.totalPriceBeforeCharges + this.bankCharge?.["12"]) / 12;

      // Manually trigger change detection
      this.cdr.detectChanges();
    }
  }

  public get showEPP() {
    return this.totalPriceBeforeCharges >= 500;
  }

  public get showEPP12Months() {
    return this.totalPriceBeforeCharges >= 1200;
  }
  public get showEPP6Months() {
    return this.totalPriceBeforeCharges >= 1000;
  }

  get paymentTypeVal() {
    return this.paymentFormGroup?.value?.paymentType;
  }

  get paymentChannelVal() {
    return this.paymentFormGroup?.value?.paymentChannel;
  }
}

<div class="upload-documents">
  <p class="upload-documents__title">{{ "uploadDocs.title" | translate }}</p>
  <p class="upload-documents__desc">{{ "uploadDocs.desc" | translate }}</p>

  <mat-card>
    <div class="upload-documents__vehicle-grant">
      <div class="upload-documents__flex">
        <div>
          <img src="assets/images/tomove/vehicle-grant.png" alt="" />
        </div>
        <div>
          <div class="bold">
            {{ "uploadDocs.vehicleGrant" | translate }}
          </div>
          <div class="upload-documents__desc">
            {{ "uploadDocs.vehicleGrantDesc" | translate }}
          </div>
          <div class="file-name bold" *ngIf="filename.VehicleGrant">
            {{ filename.VehicleGrant }}
            <button
              mat-icon-button
              (click)="deleteFile('VehicleGrant')"
              *ngIf="errorActivatedPolicy === false"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div>
        <input
          type="file"
          accept="image/*"
          id="vehicleGrant"
          (change)="handleFileInput($event, 'VehicleGrant')"
          style="display: none"
        />
        <div class="view-sample-txt" (click)="showSampleDialog('VehicleGrant')">
          View sample here
        </div>
        <button
          mat-stroked-button
          class="mt-sm"
          *ngIf="errorActivatedPolicy === false"
        >
          <label
            for="vehicleGrant"
            style="display: flex; justify-content: center; align-items: center"
          >
            <mat-icon class="">add_circle</mat-icon
            >{{ "common.upload" | translate }}
          </label>
        </button>
      </div>
    </div>
  </mat-card>
  <mat-card class="upload-documents__vehicle-photo">
    <div class="upload-documents__flex">
      <div>
        <img src="assets/images/tomove/car-icon.png" alt="" />
      </div>
      <div style="width: 100%">
        <div class="upload-documents__flex space-between">
          <div class="bold">
            {{ "uploadDocs.vehiclePhoto" | translate }}
          </div>
          <div
            class="view-sample-txt"
            (click)="showSampleDialog('VehiclePhoto')"
          >
            View sample here
          </div>
        </div>
        <div class="upload-documents__desc">
          {{ "uploadDocs.vehiclePhotoDesc" | translate }}
        </div>

        <mat-card>
          <div class="flex">
            <input
              type="file"
              accept="image/*"
              id="FrontCar"
              (change)="handleFileInput($event, 'FrontCar')"
              style="display: none"
            />
            <div>
              <p class="bold">{{ "uploadDocs.fcar" | translate }}</p>
              <div class="file-name bold" *ngIf="filename.FrontCar">
                {{ filename.FrontCar }}
                <button
                  mat-icon-button
                  (click)="deleteFile('FrontCar')"
                  *ngIf="errorActivatedPolicy === false"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <button mat-stroked-button *ngIf="errorActivatedPolicy === false">
                <label
                  for="FrontCar"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <mat-icon class="color-dark-blue">add_circle</mat-icon
                  >{{ "common.upload" | translate }}
                </label>
              </button>
            </div>
          </div>
        </mat-card>
        <mat-card>
          <div class="flex">
            <input
              type="file"
              accept="image/*"
              id="BackCar"
              (change)="handleFileInput($event, 'BackCar')"
              style="display: none"
            />
            <div>
              <p class="bold">{{ "uploadDocs.bcar" | translate }}</p>
              <div class="file-name bold" *ngIf="filename.BackCar">
                {{ filename.BackCar }}
                <button
                  mat-icon-button
                  (click)="deleteFile('BackCar')"
                  *ngIf="errorActivatedPolicy === false"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <button mat-stroked-button *ngIf="errorActivatedPolicy === false">
                <label
                  for="BackCar"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <mat-icon class="">add_circle</mat-icon
                  >{{ "common.upload" | translate }}
                </label>
              </button>
            </div>
          </div>
        </mat-card>
        <mat-card>
          <div class="flex">
            <input
              type="file"
              accept="image/*"
              id="LeftCar"
              (change)="handleFileInput($event, 'LeftCar')"
              style="display: none"
            />
            <div>
              <p class="bold">
                {{ "uploadDocs.lside" | translate }}
              </p>
              <div class="file-name bold" *ngIf="filename.LeftCar">
                {{ filename.LeftCar }}
                <button
                  mat-icon-button
                  (click)="deleteFile('LeftCar')"
                  *ngIf="errorActivatedPolicy === false"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <button mat-stroked-button *ngIf="errorActivatedPolicy === false">
                <label
                  for="LeftCar"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <mat-icon class="">add_circle</mat-icon
                  >{{ "common.upload" | translate }}
                </label>
              </button>
            </div>
          </div>
        </mat-card>
        <mat-card>
          <div class="flex">
            <input
              type="file"
              accept="image/*"
              id="RightCar"
              (change)="handleFileInput($event, 'RightCar')"
              style="display: none"
            />
            <div>
              <p class="bold">
                {{ "uploadDocs.rside" | translate }}
              </p>
              <div class="file-name bold" *ngIf="filename.RightCar">
                {{ filename.RightCar }}
                <button
                  mat-icon-button
                  (click)="deleteFile('RightCar')"
                  *ngIf="errorActivatedPolicy === false"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <button mat-stroked-button *ngIf="errorActivatedPolicy === false">
                <label
                  for="RightCar"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <mat-icon class="">add_circle</mat-icon
                  >{{ "common.upload" | translate }}
                </label>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-card>

  <button
    mat-flat-button
    class="btn-primary mt-md"
    (click)="handleSubmit()"
    [disabled]="
      fileToUpload.length === 0 ||
      (!filename.VehicleGrant &&
        (!filename.FrontCar ||
          !filename.BackCar ||
          !filename.LeftCar ||
          !filename.RightCar))
    "
  >
    {{ "common.submit" | translate }}
  </button>
</div>

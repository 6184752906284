<div class="value" [ngSwitch]="code">
	<div *ngSwitchCase="'MSIG'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.MSIG.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.MSIG.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.MSIG.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.MSIG.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.MSIG.ehailing" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.optionalCoverage' | translate }}{{ 'benefits.protectCost' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.optionalCoverage' | translate }}{{ 'benefits.waiveCost' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Pando'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.towingService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.refuelEmptyTank" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.tireReplacement" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.pando.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.pando.est" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.goodClaimsRecordDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.pando.votedBest" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.carsCoverageAgeDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.pando.insuranceAcceptance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.bestValuePrice' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.insuranceOldVehicles' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'PolicyStreet'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.towingService" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.roadsideAssistance" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.changeBatteryService" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.refuelEmptyTank" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.tireReplacement" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.additionalDriver" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.PolicyStreet.claimAssistance" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.PolicyStreet.est" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.PolicyStreet.votedBest" | translate }}
			</div>
		</div>
	
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.PolicyStreet.insuranceAcceptance" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'AXA'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXA.warranty" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXA.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXA.rideupTo30KM" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXA.additionalBenefits" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.AXA.safeDrivingDiscount' | translate }}
        <div class="desc">
          {{ 'benefits.AXA.safeDrivingDiscountDesc' | translate }}
        </div>
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXA.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXA.fastClaimApproval" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content" *ngIf="insuranceType != 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} 50km HELLO@?
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.fastClaimApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.optionalCoverage' | translate }}{{ 'benefits.ehailingAddOn' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.limitedVehiclesAge' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Sompo'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.sompo.allDriversCoverage" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.allDriversCoverageDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.sompo.roadsideAssistanceRakanAuto" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.sompo.nilExcess" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.sompo.specialPerlisCoverage" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.specialPerlisCoverageDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.sompo.ehailing" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'Takaful_Malaysia'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulMalaysia.allDriversCoverage" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulMalaysia.towingService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulMalaysia.roadsideAssistanceProgram" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulMalaysia.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulMalaysia.refuelTank" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulMalaysia.tireReplacement" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentInPolicy' |
        translate }}
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.TakafulMalaysia.takafulOperator" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.TakafulMalaysia.cashBack" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.TakafulMalaysia.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.TakafulMalaysia.fastClaimApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.TakafulMalaysia.nationwidePanelWorkshops" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.TakafulMalaysia.suitableNonMuslims" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'Liberty'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.refuelEmptyTank" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.replaceFlatTyre" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.paCoverage" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.medicalExpenses" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.keyReplacement" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Liberty.childseatReplacement" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Liberty.ehailing" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'Ikhlas'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulIkhlas.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{
				"benefits.TakafulIkhlas.roadsideAssistanceBantuanIkhlas" | translate
				}}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulIkhlas.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulIkhlas.changingFlatTyre" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulIkhlas.petrolAssist" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulIkhlas.lockSmitch" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.TakafulIkhlas.personalAccInsurance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDrivers' | translate }}
      </div>
    </div> -->

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.locksmithServices' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentCarOwner' |
        translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Allianz'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Allianz.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Allianz.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Allianz.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Allianz.bestCarInsurer" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Allianz.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Allianz.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Allianz.theftInsurer" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'Etiqa'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Etiqa.allDriversCoverage" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Etiqa.roadsideAssistance" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Etiqa.bestMotorTakaful" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'Tune'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Tune_Protect.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Tune_Protect.theftInsurer" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.guranteeWorkmanship' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.vaccinationSideEffectCoverage' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.refundDriveInitiative' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.limitedVehiclesCapacityAge' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.amountImposedEachClaim' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Zurich'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Zurich.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Zurich.claimAssistance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.fullFloodPerilCoverage' |
        translate }}
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Zurich.fastClaimApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Zurich.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Zurich.ehailing" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'RHB'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.RHB.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.RHB.roadsideAssistance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content" *ngIf="insuranceType != 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.mobileAppClaims' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Kurnia'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Kurnia.autoAssistKurnia" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Kurnia.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Kurnia.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Kurnia.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Kurnia.theftInsurer" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content" *ngIf="insuranceType != 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b>{{ 'benefits.emergencyTowing' | translate }} RM 200
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceProgram' | translate}} {{ 'benefits.viaKurnia' | translate }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType != 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.allDriversCoverage' | translate }}
        <div class="desc">
          {{ 'benefits.allDriversCoverageDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.fastClaimApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.nationwidePanelWorkshops' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Pacific'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Pacific.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.Pacific.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Pacific.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Pacific.theftInsurer" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.Pacific.fastClaimApproval" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'GrabAXA'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXAGrab.warranty" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXAGrab.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXAGrab.rideupTo30KM" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefits.AXAGrab.axaPreferredWorkshop" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.AXAGrab.safeDrivingDiscount' | translate }}
        <div class="desc">
          {{ 'benefits.AXAGrab.safeDrivingDiscountDesc' | translate }}
        </div>
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.fastClaimApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.personalAccidentCarOwner" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.medicalClaims" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.lossOfIncome" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.loanProtection" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefits.AXAGrab.wheelAlignment" | translate }}
			</div>
		</div>
	</div>
</div>
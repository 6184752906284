import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-benefit",
  templateUrl: "./benefit.component.html",
  styleUrls: ["./benefit.component.scss"],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgIf, MatIconModule, TranslateModule],
})
export class BenefitComponent {
  _code: string;
  _insuranceType: string;

  get code(): string {
    return this._code;
  }
  @Input() set code(value: string) {
    this._code = value;
  }

  get insuranceType(): string {
    return this._insuranceType;
  }
  @Input() set insuranceType(value: string) {
    this._insuranceType = value;
  }
}

import {
  AsyncPipe,
  DecimalPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from "@angular/common";
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Observable, Subscription, combineLatest, of } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";

// Angular Material
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatStepper } from "@angular/material/stepper";

// Services
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AlertService } from "src/app/shared/services/alert.service";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner.service";
import { ScreenSizeService } from "src/app/shared/services/screen-size.service";
import { QuotationService } from "../quotation/_services/quotation.service";

// Components
import { ExpandableCardComponent } from "src/app/shared/components/expandable-card/expandable-card.component";
import { BenefitComponent } from "src/app/shared/components/benefit/benefit.component";
import { PaymentOptionsComponent } from "src/app/shared/components/payment-options/payment-options.component";
import { RequestPaymentDetailsComponent } from "src/app/shared/components/request-payment-details/request-payment-details.component";
import { SectionTitleComponent } from "src/app/shared/components/section-title/section-title.component";

// Interface
import {
  IGenerateTopupPaymentPayload,
  IProductDocument,
} from "src/app/shared/interfaces/quotation.interface";
import {
  IPolicyDetail,
  ITopupRequestData,
} from "./_interfaces/topup-request.interface";

// Constants
import { MALAYSIA_STATES } from "src/app/shared/constants/constants.global";
import {
  PAYMENT_OPTION_EWALLET,
  PAYMENT_OPTION_FP,
  PAYMENT_OPTION_RAZER,
  PAYMENT_OPTION_VISA,
} from "src/app/shared/constants/payment-options";

@Component({
  selector: "app-topup-request",
  templateUrl: "./topup-request.component.html",
  styleUrls: ["./topup-request.component.scss"],
  standalone: true,
  imports: [
    RequestPaymentDetailsComponent,
    SectionTitleComponent,
    PaymentOptionsComponent,
    TranslateModule,
    MatDividerModule,
    AsyncPipe,
    DecimalPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    ExpandableCardComponent,
    NgTemplateOutlet,
    MatExpansionModule,
    BenefitComponent,
    MatCardModule,
    MatButtonModule,
    NgIf,
    NgFor,
  ],
})
export class TopupRequestComponent implements OnInit, OnDestroy {
  screenWidth: number;
  paymentFormGroup: FormGroup;
  paymentOptionFullPrice: string = PAYMENT_OPTION_FP;
  paymentOptionRazer: string = PAYMENT_OPTION_RAZER;
  paymentOptionEwallet: string = PAYMENT_OPTION_EWALLET;
  paymentOptionVisa: string = PAYMENT_OPTION_VISA;

  id: string;
  topupUserData$: Observable<ITopupRequestData>;
  policyDetail$: Observable<IPolicyDetail>;

  @ViewChild("stepper") private stepper: MatStepper;

  stateList: string[] = MALAYSIA_STATES;
  filterStateList: string[] = Object.assign([], this.stateList);
  filterDeliveryStateList: string[] = Object.assign([], this.stateList);

  productDocumentInterface: IProductDocument[] = [];
  selectedPayment: string = "IPay88";
  allowEditQuotation: boolean = false;
  isSameAddressCheck: boolean;
  paymentChannelSub: Subscription;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingSpinnerService,
    private quotationService: QuotationService,
    public screenSizeService: ScreenSizeService,
  ) {
    this.screenWidth = window.innerWidth;
    this.id = activatedRoute.snapshot.params.id;
    this.topupUserData$ = this.quotationService
      .getPolicyTopupDetail(this.id)
      .pipe(shareReplay());

    this.policyDetail$ = this.topupUserData$.pipe(map((x) => x.policyDetail));
  }

  ngOnInit() {
    this.initPaymentFormGroup();
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );
  }

  ngOnDestroy(): void {
    this.paymentChannelSub.unsubscribe();
  }

  private initPaymentFormGroup() {
    this.paymentFormGroup = new FormGroup({
      paymentType: new FormControl(this.paymentOptionFullPrice, [
        Validators.required,
      ]),
      paymentProvider: new FormControl(this.paymentOptionRazer, [
        Validators.required,
      ]),
      paymentChannel: new FormControl("", []),
      ewalletType: new FormControl("", []),
      bank: new FormControl("", []),
      installmentMonth: new FormControl(0, []),
    });
    let ewalletType = this.paymentFormGroup.controls.ewalletType;

    this.paymentChannelSub =
      this.paymentFormGroup.controls.paymentChannel.valueChanges.subscribe(
        (x) => {
          if (x == this.paymentOptionEwallet) {
            ewalletType?.setValidators([Validators.required]);
          } else {
            ewalletType?.setValidators([]);
          }
          ewalletType?.updateValueAndValidity();
        },
      );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  paymentTypeChange(paymentType: string) {
    this.selectedPayment = paymentType;
  }

  handleSubmit() {
    this.router.navigate([], {
      queryParams: {
        id: this.id,
        pay: true,
      },
    });
    this.loadingService.loadingOn(true);

    let paymentFGroup = this.paymentFormGroup?.value;
    if (paymentFGroup?.paymentChannel == this.paymentOptionEwallet)
      paymentFGroup.paymentChannel = this.paymentFormGroup?.value?.ewalletType;

    var value: IGenerateTopupPaymentPayload = {
      paymentType: "FP",
      paymentChannel: paymentFGroup.paymentChannel || this.paymentOptionVisa,
    };

    this.quotationService.generateTopupPayment(this.id, value).subscribe({
      next: (x) => {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            id: x.id,
          },
        };
        this.loadingService.loadingOff(true);
        this.router.navigate(["/payment"], navigationExtras);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
        this.loadingService.loadingOff(true);
      },
    });
  }

  getTotalPromo$(): Observable<number> {
    return this.topupUserData$.pipe(
      map((data) => data?.policyDetail?.promoCodePrice || 0),
    );
  }

  getIdentityNoLabel$(): Observable<string> {
    return this.topupUserData$.pipe(
      map((data) => {
        if (data?.policyDetail?.isMalaysian && !data?.policyDetail?.isCompany)
          return "common.icNo";
        else if (!data?.policyDetail?.isMalaysian) return "common.passport";
        return "common.businessRegistrationNo";
      }),
    );
  }

  getTax$(): Observable<number> {
    return this.topupUserData$.pipe(
      switchMap((data) => {
        if (!data?.policyDetail) return of(0);
        return this.getGrossPremium$().pipe(
          map((total) => {
            const tax = total * data.policyDetail.taxRate;
            return Number(tax.toFixed(2));
          }),
        );
      }),
    );
  }

  getBasePremium$(): Observable<number> {
    return this.topupUserData$.pipe(
      map((data) => data?.policyDetail?.totalBasePremium || 0),
    );
  }

  getTotalNetPremium$(): Observable<number> {
    return combineLatest([
      this.getGrossPremium$(),
      this.getTax$(),
      this.topupUserData$,
    ]).pipe(
      map(([grossPremium, tax, data]) => {
        if (!data?.policyDetail) return 0;
        return grossPremium + tax + data.policyDetail.stampDutyPrice;
      }),
    );
  }

  getPlatformDiscountRate$(): Observable<number> {
    return this.topupUserData$.pipe(
      map((data) => data?.policyDetail?.platformPrice || 0),
    );
  }

  getTotalCharge$(): Observable<number> {
    return combineLatest([
      this.getTotalNetPremium$(),
      this.getPlatformDiscountRate$(),
      this.topupUserData$,
    ]).pipe(
      map(([netPremium, platformDiscount, data]) => {
        let total = netPremium - platformDiscount;
        if (data?.policyDetail?.includeRoadTax) {
          total +=
            data.policyDetail.roadTaxPrice +
            data.policyDetail.roadTaxDeliveryPrice;
        }
        if (data?.policyDetail?.includeDigitalRoadTax) {
          total -= data.policyDetail.roadTaxDeliveryPrice;
        }
        return total;
      }),
    );
  }

  getServiceFee$(): Observable<number> {
    return this.topupUserData$.pipe(
      map((data) => data?.policyDetail?.servicePrice || 0),
    );
  }

  getTotal$(): Observable<number> {
    return combineLatest([
      this.getTotalCharge$(),
      this.getServiceFee$(),
      this.getTotalPromo$(),
      this.topupUserData$,
    ]).pipe(
      map(([totalCharge, serviceFee, totalPromo, data]) => {
        return (
          totalCharge +
          serviceFee -
          totalPromo +
          (data?.topUpRequest?.amount || 0)
        );
      }),
    );
  }

  getTotalPaid$(): Observable<number> {
    return combineLatest([this.getTotal$(), this.topupUserData$]).pipe(
      map(([total, data]) => total - (data?.topUpRequest?.amount || 0)),
    );
  }

  getTotalAddOnPrice$(): Observable<number> {
    return this.topupUserData$.pipe(
      map((data) => data?.addOn.reduce((acc, x) => acc + x.amount, 0) || 0),
    );
  }

  getTotalBundlePrice$(): Observable<number> {
    return this.topupUserData$.pipe(
      map(
        (data) => data?.policyBundle.reduce((acc, x) => acc + x.amount, 0) || 0,
      ),
    );
  }

  getTotalBundleAddonPrice$(): Observable<number> {
    return this.topupUserData$.pipe(
      map(
        (data) =>
          data?.policyBundleAddOn.reduce((acc, x) => acc + x.amount, 0) || 0,
      ),
    );
  }

  getGrossPremium$(): Observable<number> {
    return combineLatest([
      this.getBasePremium$(),
      this.getTotalAddOnPrice$(),
      this.getTotalBundlePrice$(),
      this.getTotalBundleAddonPrice$(),
    ]).pipe(
      map(
        ([
          basePremium,
          totalAddOnPrice,
          totalBundlePrice,
          totalBundleAddonPrice,
        ]) => {
          return (
            basePremium +
            totalAddOnPrice +
            totalBundlePrice +
            totalBundleAddonPrice
          );
        },
      ),
    );
  }

  getNameLabel$(): Observable<string> {
    return this.topupUserData$.pipe(
      map((data) => {
        if (data?.policyDetail?.isMalaysian && !data?.policyDetail?.isCompany)
          return "common.namePerIc";
        else if (!data?.policyDetail?.isMalaysian)
          return "common.namePerPassport";
        return "common.companyName";
      }),
    );
  }

  getNCD$(): Observable<number> {
    return this.topupUserData$.pipe(
      map(
        (data) =>
          data?.policyDetail?.basePremium -
          (data?.policyDetail?.totalBasePremium || 0),
      ),
    );
  }

  getTopupAmount$(): Observable<number> {
    return combineLatest([this.getTotal$(), this.getTotalPaid$()]).pipe(
      map(([total, totalPaid]) => total - totalPaid),
    );
  }

  getServiceCharge$(): Observable<number> {
    return combineLatest([this.topupUserData$, this.getTopupAmount$()]).pipe(
      map(([data, topupAmount]) => {
        const defaultServiceCharge = data?.policyDetail?.serviceTaxRate || 0;
        return Number((defaultServiceCharge * topupAmount).toFixed(2));
      }),
    );
  }

  getUnpaidRemainingAmount$(): Observable<number> {
    return combineLatest([
      this.getTopupAmount$(),
      this.getServiceCharge$(),
    ]).pipe(map(([topupAmount, serviceCharge]) => topupAmount + serviceCharge));
  }

  getAddOnCode(code) {
    return `addOn.${code}`;
  }

  addonLabel(value: number) {
    return "RM " + value.toFixed(2).toLocaleString();
  }

  showAddOnsLabelInReciept(topupUserData: ITopupRequestData) {
    if (topupUserData?.addOn || topupUserData?.policyBundleAddOn) {
      const addOns = topupUserData?.addOn || [];
      if (addOns.length == 1 && addOns[0].code == "AutomaticallyCovered") {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}

<div class="payment-option-container" (click)="$event.stopPropagation()">
  <form [formGroup]="paymentFormGroup">
    <mat-radio-group class="payment-radio" formControlName="paymentType">
      <div class="payment-radio__btn">
        <mat-radio-button [value]="paymentOptionFullPrice" class="fp-margin">
          <div class="payment-radio__label">
            {{ "orderSummary.fullPrice" | translate }}
          </div>
          <div class="payment-radio__bold">
            RM {{ this.totalPriceBeforeCharges | number : "1.2-2" }}
          </div>
          <div *ngIf="paymentTypeVal != paymentOptionFullPrice">
            <img
              src="/assets/images/tomove/payments/full-price.png"
              alt=""
              class="payment-radio__btn__icon"
            />
          </div>
        </mat-radio-button>
        <mat-radio-group
          class="payment-radio full-price space"
          *ngIf="paymentTypeVal == paymentOptionFullPrice"
          formControlName="paymentChannel"
        >
          <div class="select-method mb-sm mt-md">
            {{ "paymentOptions.paymentMethod" | translate }}
          </div>
          <mat-radio-button [checked]="true" [value]="paymentOptionVisa">
            <div>Visa / Mastercard</div>
            <img
              src="/assets/images/tomove/payments/full-price-1.png"
              alt=""
              class="full-price__icon"
            />
          </mat-radio-button>
          <mat-radio-button [checked]="false" [value]="paymentOptionFpx">
            <div>Online Banking (FPX)</div>
            <img
              src="/assets/images/tomove/payments/full-price-2.png"
              alt=""
              class="full-price__icon"
            />
          </mat-radio-button>
          <mat-radio-button
            [checked]="false"
            [value]="paymentOptionEwallet"
            *ngIf="allowEwallet"
          >
            <div>e-Wallet</div>
            <img
              src="/assets/images/tomove/payments/full-price-3.png"
              alt=""
              class="full-price__icon"
            />
          </mat-radio-button>
          <mat-form-field
            appearance="outline"
            *ngIf="paymentChannelVal == paymentOptionEwallet"
            class="e-wallet-form"
          >
            <mat-label>{{
              "paymentOptions.selectEWallet" | translate
            }}</mat-label>
            <mat-select
              disableOptionCentering
              required
              formControlName="ewalletType"
            >
              <mat-option
                *ngFor="let item of ewalletTypeListing"
                [value]="item?.value"
              >
                <span class="ewallet-options">
                  <img [src]="item?.img" alt="" />
                  {{ item?.name }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-radio-group>
      </div>
      <mat-radio-button
        [value]="paymentOptionShopback"
        [checked]="paymentTypeVal == paymentOptionShopback"
        class="payment-radio__btn"
        *ngIf="!onlyFullPayment"
      >
        <div class="payment-radio__label">
          {{ "common.shopback" | translate }}
        </div>
        <div class="mt-xs payment-radio__bold">
          <span>RM {{ this.totalPriceBeforeCharges | number : "1.2-2" }}</span>
        </div>
        <div class="payment-radio__shopback-copy">
          <span class="bold">{{ "common.shopbackPay" | translate }}&nbsp;</span>
          {{ "common.shopbackGet" | translate }}&nbsp;
          <span class="shopback-orange">
            {{ "common.shopbackCashback" | translate }}</span
          >
          <img
            src="/assets/images/tomove/payments/shopback.png"
            alt=""
            class="shopback-icon"
          />
        </div>
      </mat-radio-button>
      <mat-radio-button
        [value]="paymentOptionEPP"
        [checked]="paymentTypeVal == paymentOptionEPP"
        class="payment-radio__btn"
        *ngIf="showEPP && !onlyFullPayment"
      >
        <div class="payment-radio__label">
          {{ "paymentOptions.eppDuration" | translate }}
        </div>
        <div class="mt-xs">
          <span class="bold">{{ "paymentOptions.epp" | translate }}</span>
        </div>
        <img
          src="/assets/images/tomove/payments/visa-mastercard.png"
          alt=""
          class="payment-radio__btn__icon"
        />
        <div
          class="payment-radio__btn__epp"
          *ngIf="paymentTypeVal == paymentOptionEPP"
        >
          <mat-form-field appearance="outline">
            <mat-label>{{ "paymentOptions.cardIssuer" | translate }}</mat-label>
            <mat-select
              disableOptionCentering
              formControlName="bank"
              hideSingleSelectionIndicator
            >
              <mat-option
                *ngFor="let item of eppTypeListing"
                [value]="item?.value"
                class="epp-options"
              >
                <span class="ewallet-options">
                  <img [src]="item?.img" alt="" />
                  {{ item?.name }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="this.paymentFormGroup?.value?.bank"
          >
            <mat-label>{{
              "paymentOptions.monthlyOption" | translate
            }}</mat-label>
            <mat-select
              disableOptionCentering
              hideSingleSelectionIndicator
              formControlName="installmentMonth"
            >
              <mat-option
                value="3"
                class="epp-options"
                *ngIf="totalPriceInstallment3"
              >
                {{ "paymentOptions.3months" | translate }} (<span
                  class="epp-options__bold"
                  >RM {{ this.totalPriceInstallment3 | number : "1.2-2" }}</span
                >
                {{ "common.perMonth" | translate }})
              </mat-option>
              <mat-option
                value="6"
                class="epp-options"
                *ngIf="totalPriceInstallment6 && showEPP6Months"
              >
                {{ "paymentOptions.6months" | translate }} (<span
                  class="epp-options__bold"
                  >RM {{ this.totalPriceInstallment6 | number : "1.2-2" }}</span
                >
                {{ "common.perMonth" | translate }})
              </mat-option>
              <mat-option
                value="12"
                class="epp-options"
                *ngIf="totalPriceInstallment12 && showEPP12Months"
              >
                {{ "paymentOptions.12months" | translate }} (<span
                  class="epp-options__bold"
                  >RM
                  {{ this.totalPriceInstallment12 | number : "1.2-2" }}</span
                >
                {{ "common.perMonth" | translate }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-radio-button>
      <!-- Payex option -->
      <mat-radio-button
        [value]="paymentOptionEPP"
        [checked]="paymentTypeVal == paymentOptionPayex"
        class="payment-radio__btn"
        *ngIf="showEPP6Months && !onlyFullPayment"
      >
        <div class="payment-radio__label">
          {{ "paymentOptions.eppDuration" | translate }}
        </div>
        <div class="mt-xs">
          <span class="bold">{{ "paymentOptions.epp" | translate }}</span>
        </div>
        <img
          src="/assets/images/tomove/payments/payex.png"
          alt=""
          class="payment-radio__btn__icon"
        />
        <div
          class="payment-radio__btn__epp"
          *ngIf="paymentTypeVal == paymentOptionEPP"
        >
          <mat-form-field appearance="outline">
            <mat-label>{{ "paymentOptions.cardIssuer" | translate }}</mat-label>
            <mat-select
              disableOptionCentering
              formControlName="bank"
              hideSingleSelectionIndicator
            >
              <mat-option
                *ngFor="let item of eppTypeListing"
                [value]="item?.value"
                class="epp-options"
              >
                <span class="ewallet-options">
                  <img [src]="item?.img" alt="" />
                  {{ item?.name }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="this.paymentFormGroup?.value?.bank"
          >
            <mat-label>{{
              "paymentOptions.monthlyOption" | translate
            }}</mat-label>
            <mat-select
              disableOptionCentering
              hideSingleSelectionIndicator
              formControlName="installmentMonth"
            >
              <mat-option
                value="3"
                class="epp-options"
                *ngIf="totalPriceInstallment3"
              >
                {{ "paymentOptions.3months" | translate }} (<span
                  class="epp-options__bold"
                  >RM {{ this.totalPriceInstallment3 | number : "1.2-2" }}</span
                >
                {{ "common.perMonth" | translate }})
              </mat-option>
              <mat-option
                value="6"
                class="epp-options"
                *ngIf="totalPriceInstallment6 && showEPP6Months"
              >
                {{ "paymentOptions.6months" | translate }} (<span
                  class="epp-options__bold"
                  >RM {{ this.totalPriceInstallment6 | number : "1.2-2" }}</span
                >
                {{ "common.perMonth" | translate }})
              </mat-option>
              <mat-option
                value="12"
                class="epp-options"
                *ngIf="totalPriceInstallment12 && showEPP12Months"
              >
                {{ "paymentOptions.12months" | translate }} (<span
                  class="epp-options__bold"
                  >RM
                  {{ this.totalPriceInstallment12 | number : "1.2-2" }}</span
                >
                {{ "common.perMonth" | translate }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>

import { CommonModule, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { GuidesDialogComponent } from "../../components/guides-dialog/guides-dialog.component";
import { FaqDialogComponent } from "../../components/faq-dialog/faq-dialog.component";
import { LANGUAGE } from "../../constants/constants.global";
import { MatIconModule } from "@angular/material/icon";
import { MenuMobileComponent } from "./menu-mobile/menu-mobile.component";
import { ScreenSizeService } from "../../services/screen-size.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: true,
  imports: [
    MatMenuModule,
    TranslateModule,
    MatButtonModule,
    GuidesDialogComponent,
    FaqDialogComponent,
    MatDialogModule,
    MatIconModule,
    NgTemplateOutlet,
    CommonModule,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  languageConst = LANGUAGE;

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    public screenSizeService: ScreenSizeService,
  ) {}

  ngOnInit() {
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );
  }

  get isCurrentLangEN() {
    return this.translate.currentLang === LANGUAGE.en;
  }
  get isCurrentLangBM() {
    return this.translate.currentLang === LANGUAGE.bm;
  }

  guideDialog() {
    this.dialog.open(GuidesDialogComponent, {
      panelClass: "guide-dialog",
    });
  }

  faqDialog() {
    this.dialog.open(FaqDialogComponent, {
      panelClass: "faq-dialog",
    });
  }

  openMenu() {
    this.dialog.open(MenuMobileComponent, {
      panelClass: "menu-dialog",
      backdropClass: "menu-blackdrop",
      disableClose: true,
    });
  }

  switchLanguage(lang: string) {
    this.translate.use(lang);
  }

  whatsppRedirect() {
    const whatsappNumber = 60143703130;
    let whatsappContent = "";
    let lang =
      this.translate.currentLang == LANGUAGE.en ? LANGUAGE.en : LANGUAGE.ms;
    whatsappContent =
      lang == "ms"
        ? `Hai Policystreet, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
        : `Hi Policystreet, I would like to know more about road tax and car insurance`;

    let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappContent,
    )}&source=&data=&app_absent=&lang=${lang}`;

    window.open(url, "_blank");
  }
}

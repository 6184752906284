<div class="sample-dialog">
  <div *ngIf="for === 'VehicleGrant'">
    <img
      src="/assets/images/tomove/vehicle_grant_sample.png"
      alt=""
      width="100%"
    />
  </div>
  <div *ngIf="for === 'VehiclePhoto'">
    <img
      src="/assets/images/tomove/vehicle_photo_sample.png"
      alt=""
      width="100%"
    />
  </div>
</div>

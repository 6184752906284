import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SwiperModule } from "swiper/angular";
import { LoadingOverlayComponent } from "./shared/components/loading-overlay/loading-overlay.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [RouterOutlet, SwiperModule, LoadingOverlayComponent],
})
export class AppComponent {
  title = "PolicyStreet Vehicle Insurance";

  constructor(translate: TranslateService) {
    translate.addLangs(["en", "bm"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|bm/) ? browserLang : "bm");
  }
}

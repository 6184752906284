import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { QuotationService } from "../../quotation/_services/quotation.service";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner.service";
import { ActivatedRoute } from "@angular/router";
import { catchError, finalize, tap, throwError } from "rxjs";
import { MatButtonModule } from "@angular/material/button";

export interface IPaymentResData {
  status: any;
  data: any;
}

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
  standalone: true,
  imports: [TranslateModule, NgIf, MatIconModule, MatButtonModule],
})
export class PaymentSuccessComponent {
  data: IPaymentResData;
  refNo: string;
  // private gtmService: GoogleTagManagerService,
  constructor(
    activatedRoute: ActivatedRoute,
    private loadingService: LoadingSpinnerService,
    private quotationService: QuotationService,
  ) {
    this.refNo = activatedRoute.snapshot.queryParams.orderId;
    this.checkPayment();
  }

  private checkPayment() {
    this.loadingService.loadingOn(true);
    this.quotationService
      .checkPayment(this.refNo)
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
        tap((x: IPaymentResData) => {
          this.data = x;
          if (x.status) {
            // if (x.status) {
            //   this.gtmService.pushTag({
            //     event: "car_purchase",
            //     purchase_value: x.amount.toPrecision(2).toString(),
            //   });
            // }
          }
        }),
        finalize(() => this.loadingService.loadingOff(true)),
      )
      .subscribe();
  }

  goTo(url: string) {
    window.location.href = url;
  }
}

import { IScreenSize } from "../interfaces/common.interface";

export const DESKTOP_SCREEN_WIDTH = 1200;
export const TABLET_SCREEN_WIDTH = 900;
export const MOBILE_SCREEN_WIDTH = 650;
export const ALL_SCREEN_SIZES: IScreenSize = {
  DESKTOP: DESKTOP_SCREEN_WIDTH,
  TABLET: TABLET_SCREEN_WIDTH,
  MOBILE: MOBILE_SCREEN_WIDTH,
};

export const MALAYSIA_STATES = [
  "Wilayah Persekutuan Kuala Lumpur",
  "Johor",
  "Kedah",
  "Kelantan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Penang",
  "Perak",
  "Perlis",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terengganu",
  "Wilayah Persekutuan Labuan",
  "Wilayah Persekutuan Putrajaya",
];

export const COMMON_STATUS = {
  pendingPayment: "Pending Payment",
  processing: "Processing",
  paid: "Paid",
  accepted: "Accepted",
  rejected: "Rejected",
  cancelled: "Cancelled",
  new: "New",
  approved: "Approved",
  active: "Active",
  expired: "Expired",
  claimed: "Claimed",
};

export const LANGUAGE = {
  en: "en",
  bm: "bm",
  ms: "ms",
  EN: "EN",
  BM: "BM",
  MS: "MS", // Basically bm but for whatsapp
};

export const RELATIONSHIP_LIST = [
  { id: "Parent/Parent-in-law", name: "common.parents" },
  { id: "Spouse", name: "common.spouse" },
  { id: "Son/Daughter", name: "common.child" },
  { id: "Sibling/Sibling-in-law/Cousin/Relative", name: "common.sibling" },
  { id: "Friend/Co-worker", name: "common.friend" },
];
